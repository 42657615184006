import httpClient from '../config/http-client';

const getAll = () => {
    return httpClient
        .get(`/api/payrate-types`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};
export default {
    getAll,
};
