const ClassTypes = ['Pre', 'IE4', 'IE5', '1vs1', 'Waiting'];
const ClassPlaces = ['T-', 'G-', 'M-'];
const DateFormatType = 'DD/MM/YYYY';
const ClassTimeFormatType = 'HH:mm';

const GenderEnum = {
    Female: 'Female',
    Male: 'Male',
    Other: 'Other',
};

const Role = {
    Admin: 'Admin',
    Employee: 'Employee',
    TA: 'TA',
    Teacher: 'Teacher',
    Student: 'Student',
    Parent: 'Parent',
};

const Skill = [
    { id: 1, name: 'Listening' },
    { id: 2, name: 'Reading' },
    { id: 3, name: 'Writing' },
    { id: 4, name: 'Speaking' },
    { id: 5, name: 'Vocabulary' },
    { id: 6, name: 'Grammar' },
];

const LessonType = {
    Development: 1,
    Review: 2,
    Drill: 3,
};

const FormLayout = {
    labelCol: {
        span: 4,
        style: { textAlign: 'left' },
    },
    wrapperCol: {
        span: 20,
    },
};

const ClassCategories = [
    {
        label: 'IELTS Class',
        value: 'IELTS',
        id: 'IELTS',
        disabled: false,
    },
    { label: 'High School', value: 'PT', id: 'PT', disabled: false },
    { label: '1vs1', value: '1vs1', id: '1vs1', disabled: false },
];

export default {
    FormLayout,
    ClassTypes,
    ClassPlaces,
    DateFormatType,
    ClassTimeFormatType,
    GenderEnum,
    Role,
    Skill,
    LessonType,
    ClassCategories,
};
