import httpClient from '../config/http-client';

const generate = (payslipPeriodId) => {
    return httpClient
        .post(`/api/payslips/generate`, { payslipPeriodId: payslipPeriodId })
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAll = ({ userId, payslipPeriodId }) => {
    if (!userId && !payslipPeriodId) {
        return null;
    }
    const params = new URLSearchParams();
    payslipPeriodId && params.append('payslipPeriodId', payslipPeriodId);
    userId && params.append('userId', userId);

    return httpClient
        .get('/api/payslips', { params })
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getByPayslipPeriodId = (payslipPeriodId) => {
    return httpClient
        .get(`/api/payslips?payslipPeriodId=${payslipPeriodId}`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const update = (payslipId) => {
    return httpClient
        .put(`/api/payslips/${payslipId}`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default { generate, getAll, update, getByPayslipPeriodId };
