import httpClient from '../config/http-client';

const getLessonInRangeDate = ({ fromDate, toDate, classPlaceId }) => {
    const params = new URLSearchParams();
    fromDate && params.append('fromDate', fromDate);
    toDate && params.append('toDate', toDate);
    classPlaceId !== undefined && params.append('classPlace', classPlaceId);

    return httpClient
        .get(`/api/lesson-active`, { params })
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default { getLessonInRangeDate };
