const CurrentUserActionTypes = {
    REQUEST_GET_CURRENT_USER: 'REQUEST_GET_CURRENT_USER',
    REQUEST_GET_CURRENT_USER_FULFILLED: 'REQUEST_GET_CURRENT_USER_FULFILLED',
    REQUEST_GET_CURRENT_USER_REJECTED: 'REQUEST_GET_CURRENT_USER_REJECTED',
    SET_ALL_INFORMATION: 'SET_ALL_INFORMATION',
    SET_PERMISSIONS: 'SET_PERMISSIONS',
    DELETE_ALL_INFORMATION: 'DELETE_ALL_INFORMATION',
    SET_INFO_BY_ROLE: 'SET_INFO_BY_ROLE',
};

export default CurrentUserActionTypes;
