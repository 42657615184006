import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import apiMethods from '../http-client/api-methods';

const CenterConfigContext = createContext();

const CenterConfigContextProvider = ({ children }) => {
    const [data, setData] = useState({ classPlaces: [], classTypes: [], employees: [] });

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                apiMethods.classInfo.getAllClassPlaces(),
                apiMethods.classInfo.getAllClassTypes(),
                apiMethods.employee.getAll(),
                apiMethods.payrateTypes.getAll(),
            ]).then(([classPlaces, classTypes, employees, payrateTypes]) => {
                setData({
                    classPlaces: classPlaces.data.data,
                    classTypes: classTypes.data.data,
                    employees: employees.data.data,
                    payrateTypes: payrateTypes,
                });
            });
        };
        fetchData();
    }, []);

    const findEmployeeInfoById = useCallback(
        (id) => {
            if (!id || !data.employees.length) {
                return undefined;
            }
            return data.employees.find((e) => e.id === id);
        },
        [data.employees],
    );

    const getPlaceInfoById = useCallback(
        (classPlaceId) => {
            if (!classPlaceId || !data.classPlaces.length) {
                return undefined;
            }
            return data.classPlaces.find((e) => e.id === classPlaceId);
        },
        [data.employees],
    );

    const filterClassTypesOnCategory = (category) => {
        if (!category) {
            return data.classTypes;
        }
        if (!data.classTypes.length) {
            return [];
        }
        const classTypesOnCategory = data.classTypes
            .filter((t) => t.category === category)
            .map((type) => ({
                name: type.name,
                label: type.name,
                code: type.code,
                value: type.id,
                id: type.id,
            }));
        return classTypesOnCategory;
    };

    const getPayrateTypeById = (id) => data.payrateTypes.find((t) => t.id === id);

    const contextValues = {
        state: data,
        dispatch: {
            findEmployeeInfoById,
            filterClassTypesOnCategory,
            getPlaceInfoById,
            getPayrateTypeById,
        },
    };

    return (
        <CenterConfigContext.Provider value={contextValues}>
            {children}
        </CenterConfigContext.Provider>
    );
};

const useCenterConfigContext = () => {
    const context = useContext(CenterConfigContext);

    if (context === null) {
        throw new Error('useCenterConfigContext must be used within a CenterConfigContextProvider');
    }

    return context;
};

export { CenterConfigContextProvider, useCenterConfigContext };
