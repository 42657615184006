import httpClient from '../config/http-client';

const getAll = () => {
    return httpClient
        .get(`/api/payslip-periods`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getById = (id) => {
    return httpClient
        .get(`/api/payslip-periods/${id}`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const create = (entity) => {
    return httpClient
        .post(`/api/payslip-periods`, entity)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    getAll,
    getById,
    create,
};
