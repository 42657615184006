import httpClient from '../config/http-client';

const getAll = () => {
    return httpClient
        .get('/api/enrollments/all')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getEnrollmentRecordsWithClassId = (classId) => {
    return httpClient
        .get(`/api/enrollments/class/${classId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const createNewRecord = (value) => {
    return httpClient
        .post('/api/enrollments', value)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateRecord = (value) => {
    return httpClient
        .put(`/api/enrollments/${value.enrollmentId}`, value)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getEnrollmentDetailById = (enrollmentId) => {
    return httpClient
        .get(`/api/enrollments/${enrollmentId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateEnrollmentMultipleRecordsWithStatus = (classId, entity) => {
    return httpClient
        .put(`/api/enrollments/class/${classId}/status`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateAllAttendedAt = (classId) => {
    return httpClient
        .get(`/api/enrollments/classes/${classId}/sync-status-attended`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    getAll,
    getEnrollmentRecordsWithClassId,
    createNewRecord,
    getEnrollmentDetailById,
    updateRecord,
    updateEnrollmentMultipleRecordsWithStatus,
    updateAllAttendedAt,
};
