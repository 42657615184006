import httpClient from '../config/http-client';

const getAll = () => {
    return httpClient
        .get(`/api/payrates`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getEmployeePayrateDetail = (id) => {
    return httpClient
        .get(`/api/payrates?userId=${id}`)
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const update = (value) => {
    return httpClient
        .put(`/api/payrates/${value.id}`, value)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    getAll,
    update,
    getEmployeePayrateDetail,
};
