import logger from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import reducer from './reducers';

const DEBUG = process.env.REACT_APP_NODE_ENV === 'local';

const middleware = [
    promise,
    thunk, // neat middleware that logs actions
    DEBUG && logger,
].filter(Boolean);

const store = createStore(reducer, applyMiddleware(...middleware));

export default store;
